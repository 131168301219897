function buildProduct(circuitProduct) {
  const product = {
    IdQuotesCircuitsProduct: circuitProduct.product.IdQuotesCircuitsProduct,
    FK_IdQuote: circuitProduct.product.FK_IdQuote,
    FK_IdProduct: circuitProduct.product.FK_IdProduct,
    Token: circuitProduct.product.Token,
    outDate: circuitProduct.product.outDate,
    UnitPriceOrigin: circuitProduct.product.UnitPriceOrigin,
    UnitPriceType: circuitProduct.product.UnitPriceType,
    UnitPrice: circuitProduct.product.UnitPrice,
    Subtotal: circuitProduct.product.Subtotal,
    Status: circuitProduct.product.Status,
    Delivered: circuitProduct.product.Delivered,
    DeliveredMethod: circuitProduct.product.MethodDelivery,

    IdProduct: circuitProduct.infoProduct.IdProduct,
    FK_IdBrand: circuitProduct.infoProduct.FK_IdBrand,
    FK_IdCategory: circuitProduct.infoProduct.FK_IdCategory,
    FK_IdPaint: circuitProduct.infoProduct.FK_IdPaint,
    FK_IdTapestry: circuitProduct.infoProduct.FK_IdTapestry,
    Code: circuitProduct.infoProduct.Code,
    Name: circuitProduct.infoProduct.Name,
    Model: circuitProduct.infoProduct.Model,
    Type: circuitProduct.infoProduct.Type,
    Item: circuitProduct.infoProduct.Item,
    Destiny: circuitProduct.infoProduct.Destiny,
    keyCodeSat: circuitProduct.infoProduct.keyCodeSat,
    Qty: circuitProduct.product.Qty,
    QtyIventory: circuitProduct.infoProduct.Qty,
    QtyLowInventory: circuitProduct.infoProduct.QtyLowInventory,
    PricesDefined: circuitProduct.infoProduct.PricesDefined,
    PriceMin: circuitProduct.infoProduct.PriceMin,
    PriceMax: circuitProduct.infoProduct.PriceMax,
    PricesDefinedWholesaler: circuitProduct.infoProduct.PricesDefinedWholesaler,
    PriceMinWholesaler: circuitProduct.infoProduct.PriceMinWholesaler,
    PriceMaxWholesaler: circuitProduct.infoProduct.PriceMaxWholesaler,
    PriceLf: circuitProduct.infoProduct.PriceLf,
    PriceDr: circuitProduct.infoProduct.PriceDr,
    MaxSuggestedPrice: circuitProduct.infoProduct.MaxSuggestedPrice,
    UnitPriceInvoiceUsd: circuitProduct.infoProduct.UnitPriceInvoiceUsd,
    Tc: circuitProduct.infoProduct.Tc,
    UnitPriceInvoiceMn: circuitProduct.infoProduct.UnitPriceInvoiceMn,
    Ccost: circuitProduct.infoProduct.Ccost,
    ProvidersPriceUsd: circuitProduct.infoProduct.ProvidersPriceUsd,
    ProvidersCostingMn: circuitProduct.infoProduct.ProvidersCostingMn,
    Weight: circuitProduct.infoProduct.Weight,
    TypeWeight: circuitProduct.infoProduct.TypeWeight,
    Length: circuitProduct.infoProduct.Length,
    Width: circuitProduct.infoProduct.Width,
    Heigh: circuitProduct.infoProduct.Heigh,
    Delete: circuitProduct.infoProduct.Delete,
    created_at: circuitProduct.infoProduct.created_at,
    IdBrand: circuitProduct.infoProduct.IdBrand,
    name_brand: circuitProduct.infoProduct.name_brand,
    IdCategory: circuitProduct.infoProduct.IdCategory,
    name_categorie: circuitProduct.infoProduct.name_categorie,
    IdColorPaint: circuitProduct.infoProduct.IdColorPaint,
    NamePaint: circuitProduct.infoProduct.NamePaint,
    CodePaint: circuitProduct.infoProduct.CodePaint,
    IdColorTapestry: circuitProduct.infoProduct.IdColorTapestry,
    NameTapestry: circuitProduct.infoProduct.NameTapestry,
    CodeTapestry: circuitProduct.infoProduct.CodeTapestry,
    status_info_product: circuitProduct.infoProduct.Status,
    TrueCategory: circuitProduct.infoProduct.TrueCategory,
    OfferPrice: circuitProduct.infoProduct.OfferPrice,

    images: circuitProduct.images || circuitProduct.image,

    pieces: circuitProduct.pieces,
  }

  return product
}

export default buildProduct
